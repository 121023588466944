import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import themeUtils from '../lib/themeUtils';
import { mediaMaxWidthMixins } from '../lib/style/mixins/media';
import { Layout, Seo, Logo, BgLogos } from '../components/compounds';
import { Centered, Row, Col, Separator, EmailLink } from '../components/blocks';

const addressLinesResponsive = themeUtils.mq({
  display: ['block', 'inline-block'],
  textAlign: ['center', 'left'],
});

const IndexPage = ({ data }) => (
  <React.Fragment>
    <BgLogos />
    <Layout
      css={css`
        ${mediaMaxWidthMixins.md(`
          .Header {
            background-color: transparent;
          }
          .Header--container .logo {
            visibility: hidden;
          }

          .menu-desktop a.is-active {
            border-bottom-color: transparent;
          }
        `)}
      `}
    >
      <Seo title="Home" />
      <Centered
        css={css`
          position: absolute;
          left: 0;
          width: 100%;
          z-index: 1;
          top: 0;
          height: 100%;
        `}
      >
        <Row column>
          <Col>
            <Logo css={css({ width: '178px' })} />
          </Col>
          <Col>
            <Separator />
          </Col>
          <Col>
            <EmailLink
              target="_blank"
              href={`mailto:${data.site.siteMetadata.email}`}
              css={css`
                ${addressLinesResponsive}
              `}
            >
              {data.site.siteMetadata.email}
            </EmailLink>
          </Col>
        </Row>
      </Centered>
    </Layout>
  </React.Fragment>
);

export default IndexPage;

export const query = graphql`
  query SiteEmailQuery {
    site {
      siteMetadata {
        email
      }
    }
  }
`;

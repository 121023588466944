import { css } from '@emotion/core';
import themeUtils from '../../themeUtils';
const { breakpoints } = themeUtils;

export const mediaMinWidthMixins = Object.keys(breakpoints).reduce(
  (all, breakpoint) => {
    all[breakpoint] = content => css`
      @media (min-width: ${breakpoints[breakpoint]}px) {
        ${content}
      }
    `;
    return all;
  },
  {}
);
export const mediaMaxWidthMixins = Object.keys(breakpoints).reduce(
  (all, breakpoint) => {
    all[breakpoint] = content => css`
      @media (max-width: ${breakpoints[breakpoint] - 1}px) {
        ${content}
      }
    `;
    return all;
  },
  {}
);
